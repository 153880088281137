import React from "react";
import Pdf from "@mikecousins/react-pdf";
import { Button } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./document-edit.scss";
import "./document-view.scss";

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });
export default class DocGen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      isOpenDialog: false,
    };

    this.setPage = this.setPage.bind(this);
    this.sendToEmail = this.sendToEmail.bind(this);
  }

  setPage(newPage) {
    let prevState = { ...this.state };
    prevState.page = newPage;
    this.setState(prevState);
  }

  sendToEmail(docId) {
    // alert(docId);
    let url = "/document_templates/send_document_to_email?document_id=" + docId;
    fetch(url)
      .then((e) => {
        return e.json();
      })
      .then((e) => {
        alert(e.res);
      });
  }

  printFile(url) {
    let wnd = window.open(url);
    setTimeout(function () {
      wnd.print();
    }, 1000);
  }

  downloadFile(url) {
    let wnd = window.open(url);
    // let wnd = document.location.href = url;
    // setTimeout(function() {
    // 	wnd.print();
    // }, 1000);
  }

  handleClickOpenDialog = () => {
    this.setState({ isOpenDialog: true });
  };

  handleCloseDialog = () => {
    this.setState({ isOpenDialog: false });
  };

  render() {
    let me = this;
    let antiCaching = `?anticache=${Math.random() * 10000}${
      Math.random() * 10000
    }${Math.random() * 10000}`;

    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            primary: {
              main: "#6360FF",
            },
          },
        })}
      >
        <div
          className="document-view"
          style={{
            display: "flex",
            width: "60%",
            background: "white",
            border: "1px solid #EAEAF9",
            boxSsizing: "border-box",
            borderRadius: "20px",
          }}
        >
          {me.props.previewUrl && (
            <>
              <Pdf
                key={`page-${me.props.previewUrl}-${me.state.page}`}
                file={me.props.previewUrl + antiCaching}
                page={me.state.page}
              >
                {({ pdfDocument, pdfPage, canvas }) => (
                  <>
                    {Boolean(pdfDocument && pdfDocument.numPages) && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignContent: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            onClick={(e) => {
                              me.printFile(me.props.previewUrl);
                            }}
                            className="doc-btn"
                            startIcon={<i className="fad fa-print"></i>}
                          >
                            Напечатать
                          </Button>
                          <Button
                            onClick={(e) => {
                              me.downloadFile(me.props.previewUrl);
                            }}
                            className="doc-btn"
                            startIcon={<i className="fad fa-file-pdf"></i>}
                          >
                            Скачать PDF
                          </Button>
                          {me.props.docxUrl && (
                            <Button
                              onClick={(e) => {
                                me.downloadFile(me.props.docxUrl);
                              }}
                              className="doc-btn"
                              startIcon={<i className="fad fa-file-word"></i>}
                            >
                              Скачать DOCX
                            </Button>
                          )}
                          <Button
                            onClick={(e) => {
                              me.sendToEmail(me.props.documentId);
                            }}
                            className="doc-btn"
                            startIcon={<i className="fad fa-paper-plane"></i>}
                          >
                            Отправить
                          </Button>
                          <Button
                            onClick={() => me.handleClickOpenDialog()}
                            className="doc-btn"
                            startIcon={
                              <i className="far fa-search-plus fa-2x"></i>
                            }
                          >
                            Увеличить
                          </Button>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            alignContent: "center",
                            justifyContent: "space-around",
                            alignItems: "center",
                            marginTop: "10px",
                          }}
                        >
                          <button
                            disabled={me.state.page === 1}
                            onClick={() => me.setPage(me.state.page - 1)}
                            className="page-nav-btn"
                          >
                            <i className="fal fa-arrow-left"></i>
                          </button>
                          <span>
                            {me.state.page} / {pdfDocument.numPages}
                          </span>
                          <button
                            disabled={me.state.page === pdfDocument.numPages}
                            onClick={() => me.setPage(me.state.page + 1)}
                            className="page-nav-btn"
                          >
                            <i className="fal fa-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    )}
                    {!pdfDocument && <CircularProgress />}
                    {pdfDocument && (
                      <div
                        style={{ maxWidth: "100%" }}
                        className="canvas-wrapper"
                      >
                        {canvas}
                      </div>
                    )}
                  </>
                )}
              </Pdf>

              {me.state.isOpenDialog && (
                <Dialog
                  fullScreen
                  open={me.state.isOpenDialog}
                  onClose={me.handleCloseDialog}
                >
                  <AppBar>
                    <Toolbar>
                      <IconButton
                        color="inherit"
                        onClick={() => me.handleCloseDialog()}
                        aria-label="Закрыть"
                        style={{ marginLeft: "auto" }}
                      >
                        <i className="fal fa-times"></i>
                      </IconButton>
                    </Toolbar>
                  </AppBar>

                  <Pdf
                    key={`page-${me.props.previewUrl}-${me.state.page}`}
                    file={me.props.previewUrl + antiCaching}
                    page={me.state.page}
                  >
                    {({ pdfDocument, pdfPage, canvas }) => (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            alignContent: "center",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: "110px",
                            maxWidth: "calc(60vw)",
                            width: "calc(60vw)",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          {Boolean(pdfDocument && pdfDocument.numPages) && (
                            <>
                              <button
                                disabled={me.state.page === 1}
                                onClick={() => me.setPage(me.state.page - 1)}
                                className="page-nav-btn"
                              >
                                <i className="fal fa-arrow-left"></i>
                              </button>
                              <span>
                                {me.state.page} / {pdfDocument.numPages}
                              </span>
                              <button
                                disabled={
                                  me.state.page === pdfDocument.numPages
                                }
                                onClick={() => me.setPage(me.state.page + 1)}
                                className="page-nav-btn"
                              >
                                <i className="fal fa-arrow-right"></i>
                              </button>
                            </>
                          )}
                        </div>

                        {pdfDocument && (
                          <div className="fillwidth-canvas">{canvas}</div>
                        )}
                      </>
                    )}
                  </Pdf>
                </Dialog>
              )}
            </>
          )}
        </div>
      </ThemeProvider>
    );
  }
}
