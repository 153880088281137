import Keycloak from 'keycloak-js';
 
// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
// const keycloak = new Keycloak({
// 	url: 'https://ap.stimul.app/auth',
// 	realm: 'my-election-stg',
// 	clientId: 'account',
// 	silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
// 	redirectUri: document.location.href
// 	// onLoad: 'login-required'
// });


const keycloak = new Keycloak({
	url: 'https://auth.election.ru.com',
	realm: 'my-election-stg',
	clientId: 'keycloak_auth',
	silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
	redirectUri: document.location.href
	// onLoad: 'login-required'
});
 
// alert(keycloak);
export default keycloak;