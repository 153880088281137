import React from "react";
import TextField from "@material-ui/core/TextField";
import AsyncSelect from "react-select/async";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import "./document-edit.scss";

const customStyles = {
  placeholder: (provided, state) => ({
    ...provided,
    textAlign: "left",
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
  }),
};

const customField = {
  background: "#FFFFFF",
  border: "1px solid #EAEAF9",
  boxSizing: "border-box",
  borderRadius: "43px",
  width: "100%",
  padding: "10px",
  fontSize: "18px",
};

export default class DocumentField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parsel: {},
      user_field_datum: {},
      defaultValue: null,
      padezhesValue: {},
      padezhesOpen: false,
      error: null,
      regex_code: null,
      possible_values_src: null,
      field_input_regexes: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.checkValue = this.checkValue.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.openPadezh = this.openPadezh.bind(this);
    this.handlePadezhChange = this.handlePadezhChange.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
  }

  componentDidMount() {
    // alert(this.props.checkValue);
    if (!!this.props.checkValue) {
      let me = this;
      // alert(JSON.stringify(this.props.field));
      setTimeout(function () {
        me.checkValue(me.props.field);
      }, 200);
    }
  }

  // <DocumentField setValue={me.setValue} field={x} />
  componentWillUnmount() {}

  async checkValue(inputFieldName) {
    let me = this;
    return new Promise((resolve, reject) => {
      let url = `/input_fields/get_value_by_user?input_field_name=${inputFieldName.field}`;
      // console.log(url);
      fetch(url)
        .then((e) => {
          return e.json();
        })
        .then((e) => {
          try {
            me.props.ping();
          } catch (e) {}

          // alert(JSON.stringify(e));
          if (e) {
            let prevState = { ...me.state };
            prevState.user_field_datum = e;
            prevState.defaultValue = e.value;
            prevState.padezhesValue = {
              value: e.value,
              ime: e.value,
              vin: e.vin,
              rod: e.rod,
              dat: e.dat,
              tvo: e.tvo,
              pre: e.pre,
            };

            // if (e.regex_code) {
            //   prevState.regex_code = e.regex_code;
            // }

            if (e.field_input_regexes) {
              // alert(e.field_input_regexes.length);
              prevState.field_input_regexes = e.field_input_regexes;
            }

            if (e.possible_values_src) {
              if (e.possible_values_src.length > 3)
                prevState.possible_values_src = e.possible_values_src;
            }

            me.setState(prevState);
            if (!!me.props.handleChange) {
              // alert(JSON.str(e) + " "+e.value);
              me.props.handleChange(inputFieldName, prevState.padezhesValue);
            }

            resolve(e);
          } else {
            resolve(null);
          }
        });
    });
  }

  async setValue(inputFieldName, value) {
    // alert(JSON.stringify(inputFieldId));
    return new Promise((resolve, reject) => {
      let url = `/input_fields/set_value_by_user?input_field_name=${inputFieldName.field}&value=${value}`;
      fetch(url)
        .then((e) => {
          return e.json();
        })
        .then((e) => {
          if (e) {
            resolve(e);
          } else {
            resolve(null);
          }
        });
    });
  }

  handleChange(field, val) {
    let me = this;
    let prevState = { ...this.state };
    prevState.defaultValue = val;
    prevState.parsel[field.field] = val;
    prevState.padezhesValue.value = val;
    me.setState(prevState, () => {
      if (me.sendTimeOut) clearTimeout(me.sendTimeOut);
      me.sendTimeOut = setTimeout(() => {
        me.setValue(field, val).then(() => {
          if (!!me.props.handleChange)
            me.props.handleChange(field, prevState.padezhesValue);
        })
      }, 1000);
    });

  }

  handleClose() {
    let prevState = { ...this.state };
    prevState.padezhesOpen = false;
    this.setState(prevState);
  }

  loadOptions(inputVal, callBack) {
    let url = this.state.possible_values_src;
    if (url.indexOf("https://") == -1) {
      url = "https://election.ru.com/" + url + "?query=" + encodeURI(inputVal);
    }
    fetch(url)
      .then((e) => {
        return e.json();
      })
      .then((e) => {
        callBack(e);
      });
  }

  openPadezh() {
    let prevState = { ...this.state };
    prevState.padezhesOpen = true;
    this.setState(prevState);
  }

  handlePadezhChange(field, padezh, value) {
    let me = this;
    let prevState = { ...this.state };
    prevState.user_field_datum[padezh] = value;
    // alert(JSON.stringify(padezh));
    let url = `/input_fields/set_padezh_value?user_field_datum_id=${prevState.user_field_datum.id}&padezh=${padezh}&value=${value}`;
    // alert(url);
    me.setState(prevState);
    if (!!me.props.handlePadezhChange)
      me.props.handlePadezhChange(me.props.field.field, padezh, value);
    fetch(url);
    // alert(url);
    // alert(padezh + " " + value);
  }

  render() {
    let x = this.props.field;
    let me = this;
    let padezhes = [
      ["Родительный падеж", "rod"],
      ["Дательный падеж", "dat"],
      ["Винительный падеж", "vin"],
      ["Творительный падеж", "tvo"],
      ["Предложный падеж", "pre"],
    ];
    let error = false;
    let value = null;
    let regexErrors = [];

    if (!!this.state.defaultValue && this.state.defaultValue.length > 0) {
      value = this.state.defaultValue;
    }

    if (value && me.state.regex_code) {
      error = !value.match(new RegExp(me.state.regex_code));
    }

    if (value && me.state.field_input_regexes.count > 0) {
    }

    if (!value) {
      error = true;
      regexErrors.push("Требуется заполнить");
    }

    let textFieldDesign = { ...customField };

    let nRows = x.name ? x.name.split(" ").length : 1;
    if (nRows > 1) {
      textFieldDesign.borderRadius = "5px";
    }

    if (me.state.field_input_regexes.length > 0) {
      for (let j in me.state.field_input_regexes) {
        if (
          value &&
          !value.match(new RegExp(me.state.field_input_regexes[j].content))
        ) {
          error = true;
          regexErrors.push(me.state.field_input_regexes[j].content);
        }
      }
    }
    // alert(regexErrors.length);

    if (error) {
      textFieldDesign.background = "#FFCDD2";
    }

    let xxx = (
      <ThemeProvider
        theme={createTheme({
          palette: {
            primary: {
              main: "#6360FF",
            },
          },
        })}
      >
        <div style={{ position: "relative", marginBottom: "10px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignContent: "center",
              justifyContent: "flex-start",
              alignItems: "baseline",
              marginBottom: "10px",
            }}
            key={`x-${me.props.field.index}`}
          >
            {regexErrors.length > 0 && (
              <div style={{ marginRight: "5px" }}>
                <svg
                  width="11"
                  height="12"
                  viewBox="0 0 11 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="5.5" cy="6" r="5.5" fill="#FE5920" />
                </svg>
              </div>
            )}
            <div>
              <p
                style={{
                  marginBottom: "0px",
                  marginTop: "0px",
                  fontSize: "14px",
                  color: "#6360FF",
                }}
              >
                {x.name}
              </p>
            </div>
          </div>

          <div
            className="settings-wrapper"
            key={`wrapper-${me.props.field.index}`}
          >
            {!me.state.possible_values_src && (
              // <textarea
              //   notched={true}
              //   value={value}
              //   style={textFieldDesign}
              //   multiline
              //   rows={nRows}
              //   onChange={(e) => me.handleChange(x, e.target.value)}
              //   key={`field-${x}`}
              // />

              <TextField
                key={`field-${me.props.field.index}`}
                value={value || ""}
                placeholder="Заполните поле..."
                variant="outlined"
                color="primary"
                fullWidth
                margin="dense"
                onChange={(e) => me.handleChange(x, e.target.value)}
              />
            )}
            {me.state.possible_values_src && (
              <AsyncSelect
                onChange={(ev) => {
                  me.handleChange(ev.field, ev.value);
                }}
                key={`select-field-${me.props.field.index}`}
                style={{ customStyles }}
                defaultOptions
                loadOptions={me.loadOptions}
                loadingMessage={() => "Загрузка..."}
                placeholder={""}
                label={""}
                noOptionsMessage={() => "Введите несколько символов"}
              />
            )}

            {!me.state.possible_values_src && (
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => me.openPadezh()}
                className="set-sklonenija"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.5464 4.64654L10.6027 15.5903L9.87043 19.858L14.1382 19.1258L25.0819 8.18207C26.0582 7.20576 26.0582 5.62285 25.0819 4.64654C24.1056 3.67023 22.5227 3.67023 21.5464 4.64654ZM22.607 5.7072L12.0004 16.3138L13.4147 17.728L24.0213 7.12141C24.4118 6.73089 24.4118 6.09772 24.0213 5.7072C23.6307 5.31668 22.9976 5.31667 22.607 5.7072ZM14.7501 6.00042C15.1643 6.00042 15.5001 6.3362 15.5001 6.75042C15.5001 7.16463 15.1643 7.50042 14.7501 7.50042H8.50012C7.39555 7.50042 6.50012 8.39585 6.50012 9.50042V21.5004C6.50012 22.605 7.39555 23.5004 8.50012 23.5004H20.5001C21.6047 23.5004 22.5001 22.605 22.5001 21.5004V15.2504C22.5001 14.8362 22.8359 14.5004 23.2501 14.5004C23.6643 14.5004 24.0001 14.8362 24.0001 15.2504V21.5004C24.0001 23.4334 22.4331 25.0004 20.5001 25.0004H8.50012C6.56713 25.0004 5.00012 23.4334 5.00012 21.5004V9.50042C5.00012 7.56742 6.56713 6.00042 8.50012 6.00042H14.7501Z"
                  fill="#B1AFFF"
                />
              </svg>
            )}
          </div>

          {/* {!me.state.possible_values_src && (
          <span className="set-sklonenija" onClick={me.openPadezh}>
            <span className="hoverable">Настроить склонения</span>✍️
          </span>
        )} */}
          {/* {regexErrors.map((e) => (
          <p style={{ color: "#f00", margin: 0, marginBottom: "5px" }}>{e}</p>
        ))} */}
          <Dialog
            open={me.state.padezhesOpen}
            onClose={me.handleClose}
            aria-labelledby="form-dialog-title"
            className="padezh-dialog"
          >
            <DialogTitle id="padezh-dialog-title">
              {"Настроить падежи"}
            </DialogTitle>
            <DialogContent
              className="padezh-dialog-content"
              style={{ paddingTop: "16px" }}
            >
              {/* <div
                style={{
                  minWidth: "320px",
                  width: "70vw",
                  display: "flex",
                  flexDirection: "column",
                  padding: "0",
                }}
              > */}
              {padezhes.map((e, index) => (
                <div style={{ marginBottom: "16px" }} key={`xyz-${index}`}>
                  <p
                    style={{
                      marginBottom: "0px",
                      marginTop: "0px",
                      fontSize: "14px",
                      color: "#6360FF",
                    }}
                  >
                    {[e[0]]}
                  </p>
                  <TextField
                    key={`padezh-${e[1]}-${me.props.field.index}-${index}`}
                    onChange={(x) => {
                      me.handlePadezhChange(
                        me.props.field,
                        e[1],
                        x.target.value
                      );
                    }}
                    value={me.state.user_field_datum[e[1]]}
                    defaultValue={me.state.user_field_datum[e[1]]}
                    placeholder={[e[0]]}
                    variant="outlined"
                    color="primary"
                    fullWidth
                    margin="dense"
                  />
                </div>
              ))}
              {/* </div> */}
              {/*<TextField value={me.state.padezhIme} style={{width: "100%"}} multiline rows={x.name ? x.name.split(" ").length : 1} onChange={(e) => me.handleChange(x, e.target.value) } key={`field-${x}`} variant="outlined" label={x.name}/>  */}
            </DialogContent>
          </Dialog>
        </div>
      </ThemeProvider>
    );
    return xxx;
  }
}
