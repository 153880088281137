import React from "react";
import format from "date-fns/format";
import DateFnsUtils from "@date-io/date-fns";
import $ from "jquery";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ruLocale from "date-fns/locale/ru";

const localeMap = {
  ru: ruLocale,
};

class RuLocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, "LLLL", { locale: this.locale });
  }

  getDatePickerHeaderText(date) {
    return format(date, "dd MMMM", { locale: this.locale });
  }
}

const localeUtilsMap = {
  ru: RuLocalizedUtils,
};

const localeFormatMap = {
  ru: "dd MM yyyy",
};

const localeCancelLabelMap = {
  ru: "отмена",
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class NewProject extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      projectName: "",
      projectDate: null,
      isOpenAlert: false,
    };

    this.loadProjects = this.loadProjects.bind(this);
  }

  handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ isOpenAlert: false });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  createProject = () => {
    let me = this;
    $.ajax({
      url: `/projects/create`,
      method: "POST",
      dataType: "json",
      data: {
        name: this.state.projectName,
        voting_date: this.state.projectDate,
      },
      success: function (e) {
        me.setState({ isOpenAlert: true });
        me.loadProjects();
      },
      error: function (e) {
        console.log("error", e);
      },
    });
    this.handleClose();
  };

  loadProjects() {
    this.props.loadProjects();
  }

  handleDateChange = (date) => {
    this.setState({ projectDate: date });
  };

  render() {
    let me = this;
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            primary: {
              main: "#6360FF",
            },
            success: {
              main: "#7DCF2A",
            },
          },
        })}
      >
        <div>
          <div className="new-project-btn" onClick={me.handleClickOpen}>
            <i className="fal fa-plus-circle fa-2x"></i>
            <span>Создать проект</span>
          </div>
          <Dialog
            open={me.state.open}
            onClose={me.handleClose}
            aria-labelledby="Новый проект"
            aria-describedby="Создание нового проекта"
            className="new-project-dialog"
          >
            <DialogTitle id="new-project-dialog-title">
              {"Создать проект"}
            </DialogTitle>
            <DialogContent className="project-dialog-content">
              <div>
                <TextField
                  required
                  placeholder="Название проекта"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  margin="dense"
                  value={me.state.projectName}
                  onChange={(e) => me.setState({ projectName: e.target.value })}
                  style={{ marginBottom: "10px", marginTop: "0px" }}
                />
                <MuiPickersUtilsProvider
                  // utils={DateFnsUtils}
                  // locale={localeMap["ru"]}
                  utils={localeUtilsMap["ru"]}
                  locale={localeMap["ru"]}
                >
                  <KeyboardDatePicker
                    required
                    disableToolbar
                    variant="outlined"
                    format={localeFormatMap["ru"]}
                    cancelLabel={localeCancelLabelMap["ru"]}
                    invalidDateMessage="Неправильный формат даты"
                    color="primary"
                    fullWidth
                    id="date-picker-inline"
                    placeholder="Дата голосования (дд мм гггг)"
                    value={me.state.projectDate}
                    onChange={me.handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    className="voting-date-picker"
                  />
                </MuiPickersUtilsProvider>
              </div>
            </DialogContent>
            <DialogActions className="project-dialog-actions">
              <button
                className="action-btn"
                onClick={() => me.createProject()}
                style={{ width: "300px", margin: "0px auto 10px auto" }}
              >
                Сохранить
              </button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={me.state.isOpenAlert}
            autoHideDuration={6000}
            onClose={me.handleCloseAlert}
          >
            <Alert onClose={me.handleCloseAlert} severity="success">
              Проект успешно создан
            </Alert>
          </Snackbar>
        </div>
      </ThemeProvider>
    );
  }
}
