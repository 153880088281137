import React from "react";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import "./document-edit.scss";
import DocumentField from "./DocumentField";
import Button from "@material-ui/core/Button";
import $ from "jquery";

var numOfCheckedFields = 0;

export default class DocGen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      parsel: {},
      fields: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.sendParsel = this.sendParsel.bind(this);
    this.loadFields = this.loadFields.bind(this);
    this.ping = this.ping.bind(this);
    this.handlePadezhChange = this.handlePadezhChange.bind(this);
  }

  async checkValue(inputFieldName) {
    // alert(inputFieldName);

    return new Promise((resolve, reject) => {
      let url = `/input_fields/get_value_by_user?input_field_name=${inputFieldName.field}`;
      fetch(url)
        .then((e) => {
          return e.json();
        })
        .then((e) => {
          if (e) {
            resolve(e);
          } else {
            resolve(null);
          }
        });
    });
  }

  async setValue(inputFieldName, value) {
    // alert(JSON.stringify(inputFieldId));
    return new Promise((resolve, reject) => {
      let url = `/input_fields/set_value_by_user?input_field_name=${inputFieldName.field}&value=${value}`;
      fetch(url)
        .then((e) => {
          return e.json();
        })
        .then((e) => {
          if (e) {
            resolve(e);
          } else {
            resolve(null);
          }
        });
    });
  }

  async loadFields() {
    let me = this;
    return new Promise(async (resolve, reject) => {
      let fields = [];

      try {
        fields = JSON.parse(me.props.document_template.document_schema);
      } catch (e) {}

      for (let fieldIndex in this.props.fields) {
        fields.push(me.props.fields[fieldIndex]);
      }

      let prevState = { ...me.state };
      prevState.parsel = {};
      prevState.fields = fields;
      me.setState(prevState);
      setTimeout(function () {
        resolve(true);
      }, 700);
    });
  }

  componentDidMount() {
    let me = this;
    me.loadFields().then((e) => {
      me.sendParsel();
    });
  }

  ping() {
    let me = this;
    numOfCheckedFields += 1;
    // alert(numOfCheckedFields + " " + this.state.fields.length);
    if (numOfCheckedFields == this.state.fields.length) {
      setTimeout(me.sendParsel, 500);
      //   // return;
    }
  }

  handleChange(field, val) {
    let me = this;

    // alert(field+" "+JSON.stringify(val));
    let prevState = { ...this.state };
    if (Object.keys(val).length > 0) prevState.parsel[field.field] = val;
    else prevState.parsel[field.field] = null;
    this.setState(prevState);
    me.setValue(field, val.value);
  }

  handlePadezhChange(field, pad, val) {
    let me = this;
    let prevState = { ...me.state };
    // alert(field);
    if (prevState.parsel[field]) {
      prevState.parsel[field][pad] = val;
      // alert
    }

    // alert(JSON.stringify(me.state.parsel))
    // alert(field+" "+ pad + " "+ JSON.stringify(val));

    // // if (Object.keys(val).length > 0) {
    // if (!!prevState.parsel[field]) {
    // 	prevState.parsel[field][pad] = val;
    // } else {
    // 	prevState.parsel[field] = {pad: val};
    // }
    // // }
    me.setState(prevState);
    // }

    // let me = this;
    // alert(field+" "+JSON.stringify(val));
    // let prevState = {... this.state};
    // if (Object.keys(val).length > 0)
    // 	prevState.parsel[field.field] = val;
    // else
    // 	prevState.parsel[field.field] = null;
    // this.setState(prevState);
    // me.setValue(field, val.value);
  }

  sendParsel() {
    let me = this;
    this.props.triggerWait();
    // alert(JSON.stringify(this.state.parsel));
    let url = `/document_templates/${me.props.document_template.id}/generate_document`;
    // alert(url);

    $.ajax({
      url: url,
      dataType: "json",
      type: "POST",
      data: me.state.parsel,
      success: function (res) {
        // alert(JSON.stringify(res));
        me.props.setPreviewUrl(res.preview_url, res.document_id, res.doc_url);
        me.props.triggerWait();
      },
      error: function (res) {
        me.props.triggerWait();
      },
    });
  }

  render() {
    let me = this;
    // alert(me.state.fields.length);

    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            primary: {
              main: "#6360FF",
            },
          },
        })}
      >
        <div
          className="document-form"
          style={{
            color: "#303030",
            background: "#FFF",
            display: "flex",
            width: "40%",
            border: "1px solid #EAEAF9",
            borderRadius: "20px",
            padding: "20px",
            height: "fit-content",
          }}
        >
          <p
            style={{
              color: "#6360FF",
              fontSize: "28px",
              lineHeight: "40px",
              margin: "20px 0px",
              fontStyle: "normal",
              fontWeight: "normal",
            }}
          >
            Заполните поля ({me.props.fields.length})
          </p>
          {/*<p>{me.state.fields[0]}</p>*/}
          {/* <hr />
        <Button
          onClick={me.sendParsel}
          variant="contained"
          color="primary"
          style={{ background: "#716EFC" }}
        >
          Cохранить изменения
        </Button>
        <br /> */}
          {me.state.fields.map((x, index) => (
            <DocumentField
              key={`kk-${index}`}
              ping={me.ping}
              field={{ field: x.field, name: x.name, index: index }}
              handlePadezhChange={me.handlePadezhChange}
              checkValue={true}
              handleChange={me.handleChange}
            />
          ))}
          <Button
            onClick={() => me.sendParsel()}
            variant="contained"
            color="secondary"
            style={{
              borderRadius: "25px",
              width: "140px",
              height: "50px",
              marginLeft: "auto",
              background: "#0DE147",
            }}
          >
            <Typography
              style={{
                color: "#fff",
                fontWeight: "600",
                fontSize: "14px",
                fontFamily: "Manrope",
                fontStyle: "normal",
                margin: "0px 10px",
                padding: "15px 30px",
                textTransform: "capitalize",
              }}
            >
              Применить
            </Typography>
          </Button>
        </div>
      </ThemeProvider>
    );
  }
}
