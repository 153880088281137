import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import $ from "jquery";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./document-status.scss";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class DocGen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentStatus: "Выберите статус",
      isOpenAlert: false,
    };
  }

  handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ isOpenAlert: false });
  };

  componentDidMount() {
    let me = this;
    if (me.props.documentStatus)
      me.setState({ documentStatus: me.props.documentStatus });
  }

  handleChangeDocumentStatus = (event) => {
    let me = this;
    // console.log(event.target.value);
    me.setState({ documentStatus: event.target.value });

    $.ajax({
      url: "/document_templates/update_document_status",
      dataType: "json",
      type: "POST",
      data: {
        document_id: me.props.documentId,
        status: event.target.value,
      },
      success: function (res) {
        me.setState({ isOpenAlert: true });
      },
      error: function (res) {
        console.log(res);
      },
    });
  };

  render() {
    let me = this;
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            primary: {
              main: "#6360FF",
            },
            success: {
              main: "#7DCF2A",
            },
          },
        })}
      >
        <Select
          value={me.state.documentStatus}
          onChange={me.handleChangeDocumentStatus}
          variant="outlined"
          margin="dense"
          className="status-selector"
          fullWidth
        >
          <MenuItem disabled value={"Выберите статус"}>
            Выберите статус
          </MenuItem>
          <MenuItem value={"Задача выполнена"}>Задача выполнена</MenuItem>
        </Select>
        <Snackbar
          open={me.state.isOpenAlert}
          autoHideDuration={6000}
          onClose={me.handleCloseAlert}
        >
          <Alert onClose={me.handleCloseAlert} severity="success">
            Статус документа обновлен
          </Alert>
        </Snackbar>
      </ThemeProvider>
    );
  }
}
