import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./projects.scss";

export default class Project extends React.Component {
  constructor(props) {
    super(props);
  }
  
  goToKnowledgeBase() {
    document.location.href="/knowledge_base";
  }


  render() {
    const name = this.props.name;
    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            primary: {
              main: "#6360FF",
            },
          },
        })}
      >
        <div className="project-card" onClick={this.goToKnowledgeBase} >
          <span className="project-card-title">{name}</span>
          <div className="project-card-total-text">
            <span className="caption">Всего документов</span>
            <span className="number">25</span>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
