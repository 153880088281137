import React from "react";
import "./projects.scss";
import Project from "./Project";
import NewProject from "./NewProject.jsx";

export default class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      idToken: null,
      authenticated: false,
      updateIntervalId: null,
      user: null,
    };

    this.startCheckInterval = this.startCheckInterval.bind(this);
    this.updateUserStatus = this.updateUserStatus.bind(this);
  }

  startCheckInterval() {
    let me = this;
    let prevState = { ...this.state };
    let id = setInterval(me.updateUserStatus, 2000);
    prevState.updateIntervalId = id;
    me.setState(prevState);
  }

  updateUserStatus() {
    // alert(8);
    let me = this;
    if (document.user) {
      // alert(document.user);
      let prevState = this.state;
      prevState.user = document.user;
      me.setState(prevState);
      clearInterval(prevState.updateIntervalId);
      me.loadProjects();
    }
  }

  async loadProjects() {
    let me = this;
    let prevState = { ...this.state };

    return new Promise((resolve) => {
      const url = "/projects";
      fetch(url)
        .then((e) => {
          return e.json();
        })
        .then((e) => {
          prevState.projects = [...e.projects];
          me.setState(prevState);
          // me.setState({ projects: [...e.projects] });
          resolve(e);
        });
    });
  }

  // onKeycloakEvent(e) {
  //   let me = this;
  //   if (e == "onAuthSuccess") authSuccess = true;
  //   if (e == "onReady") {
  //     if (!authSuccess) {
  //       if (!document.user) {
  //         document.user = null;
  //         let prevState = { ...me.state };
  //         prevState.user = null;
  //         me.setState(prevState);
  //       }
  //     }
  //   }
  //   console.log("HERE!!!!!");
  //   if (authSuccess) {
  //     this.loadProjects();
  //     // keycloak.login();
  //   }
  // }

  componentDidMount() {
    let me = this;
    me.startCheckInterval();
    // keycloak.init({ onLoad: "login-required" }).then((authenticated) => {
    //   this.setState({ authenticated: authenticated });
    //   this.loadProjects();
    // });
    //   this.loadProjects();
  }

  render() {
    let me = this;
    return (
      <div>
        {me.state.user && (
          <>
            <NewProject loadProjects={this.loadProjects.bind(this)} />
            <div className="project-list">
              {me.state.projects &&
                me.state.projects.map((project) => (
                  <Project key={`project-${project.id}`} name={project.name} />
                ))}
            </div>
          </>
        )}
      </div>
    );
  }
}
