import React from "react";
import $ from "jquery";
import { CircularProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./header.scss";

var authSuccess = false;

const styles = {
  customizeToolbar: {
    minHeight: 90,
  },
};

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();

  try {
    return document.getElementById("ray").textContent;
  } catch (e) {
    return null;
  }
}

export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idToken: null,
      user: undefined,
      drawer: false,
      openedButtons: {},
      leftMenuButtons: []
    };

    this.onKeycloakTokens = this.onKeycloakTokens.bind(this);
    this.onKeycloakEvent = this.onKeycloakEvent.bind(this);
    this.getMineId = this.getMineId.bind(this);
    this.getMineData = this.getMineData.bind(this);
    this.signInToken = this.signInToken.bind(this);


    // this.toggleDrawer = this.toggleDrawer.bind(this);
  }

  getMineId() {
    const me = this;
    const url = "https://myel-digitsys.ru/admin/hasura-api/auth";
    fetch(url, {
      method: "GET",
      headers: {
        "Cookie": `m10n-dev=${me.state.idToken}`
      }
    })
  }

  getMineData(_token) {
    return;
    const me = this;
    const url = "https://hq.myel-digitsys.ru/v1/graphql";
    const headers = new Headers();
    headers.append("Cookie", `m10n-dev=${_token}`);
    // console.log(_token);
    // console.log(headers);
    fetch(url, {
      method: "POST",
      headers: headers,
      body: `{"operationName":"UseMe_GetUserData","variables":{"id":"${me.state.user.user_id}"},"query":"query UseMe_GetUserData($id: Int!) {\\n  myelection_auth_user_by_pk(id: $id) {\\n    id\\n    email\\n    first_name\\n    last_name\\n    middle_name\\n    state\\n    username\\n    role\\n    chosen_region\\n    is_reader_only\\n    __typename\\n  }\\n}\\n"}`
    }).then((_res) => {
      return _res.json();
    }).then((_x) => {
      // let prevState = {...me.state};
      // prevState.leftMenuButtons = _x.left_menu_buttons
      // prevState.user_data = _x.user_data
      // me.setState(prevState);
      // me.setState({
      //   userName: `_x`
      // })
      // console.log(_x)
    })
  }

  toggleDrawer = (open) => (event) => {
    if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    let me = this;
    me.setState({ drawer: open });
  };

  onKeycloakEvent(e) {
    return;
    let me = this;
    if (e == "onAuthSuccess") authSuccess = true;
    if (e == "onReady") {
      if (!authSuccess) {
        if (!document.user) {
          document.user = null;
          let prevState = { ...me.state };
          prevState.user = null;
          me.setState(prevState);
        }
      }
    }
    if (authSuccess == false) {
      const token = getCookie("m10n-dev");
      // alert(token);
      // alert(123);
      // keycloak.login();
    }
  }

  currentTitle() {
    const url = window.location.pathname;
    switch (url) {
      case "/":
        return "Юридический модуль";
      case "/users/show":
        return "Настройки";
      case "/knowledge_base":
        return "База знаний";
      default:
        if (url.includes("/document_templates/viewer")) return "База знаний";
        if (url.includes("/document_templates")) return "Конструктор";
        return "Юридический модуль";
    }
  }

  onKeycloakTokens(e) {
    let me = this;
    // alert(JSON.stringify(e));
    if (e.idToken) {
      let prevState = { ...this.state };
      prevState.idToken = e.idToken;
      this.setState(prevState);
      me.signInToken(e.idToken);
    }
  }

  signInToken(token) {
    let me = this;
    return new Promise((_resolve) => {
      let data = {
        token: token,
      };
      // alert(JSON.stringify(me.props));
      if (me.props.total_sign_out) {
        // alert(222);
        document.keycloak.logout();
        return;
      }

      $.ajax({
        url: `/users/sign_in_token`,
        method: "POST",
        dataType: "json",
        data: data,
        success: function (e) {
          // document.user = e;
          let prevState = { ...me.state };
          // prevState.user = e;
          prevState.leftMenuButtons = e.left_menu_buttons
          if (e.left_menu_buttons.length === 0) {
            window.open(`${e.domain}/campaigns/`, '_self');
            return;
          }
          prevState.userData = e.user_data
          // prevState.user_data = _x.user_data
          me.setState(prevState, () => {
            document.user = e.user_data;
            _resolve(true);
          });
          // alert(JSON.stringify(e));
        },
        error: function (e) {},
      });
    })

  }

  componentDidMount() {
    let me = this;
    const token = getCookie("m10n-dev");
    // alert(document.cookie);
    // browser.cookies.getAll(
    //     {domain: ""}
    // )
    if (token) {
      me.signInToken(token).then((_e) => {
        let prevState = { ...me.state };
        // prevState.user = _e;
        prevState.idToken = token;
        me.setState(prevState, () => {
          me.getMineData(token);
        });
      });
      return;
    }
    else document.location.href = ("https://myel-digitsys.ru/login");
    // console.log("user", me.state.user);
    // if (me.state.user === null || me.state.user === undefined) keycloak.login();
    // alert(22);
    // alert(this.props.total_sign_out);
  }

  render() {
    let me = this;

    return (
        <ThemeProvider
            theme={createTheme({
              palette: {
                primary: {
                  main: "#6360FF",
                },
              },
              disableRipple: true,
            })}
        >

          {!!me.state.idToken && (
              <Drawer
                  anchor="left"
                  open={me.state.drawer}
                  onClose={me.toggleDrawer(false)}
                  className="drawer-menu"
              >
                <div className={"user-part"}>
                  <i
                      className="fad fa-user-circle fa-3x"
                      style={{ color: "#6360FF", textAlign: "center" }}
                  ></i>
                  { me.state.userData &&
                    <span className={"user-name"}>
                      <p className={"title"}>Пользователь</p>
                      <p className={"name"}>{`${me.state.userData.last_name} ${me.state.userData.first_name}`}</p>
                    </span>
                  }
                </div>


                <div className={"v-divider"}/>
                <div className={"content"}>
                  { me.state.leftMenuButtons.map((_b, _bIndex) => {
                    return <><div className={"link"}
                                  style={_b.color ? {background: _b.color} : {}}
                                  onClick={() => {
                      if (_b.children) {
                        let openedButtons = {...me.state.openedButtons};
                        openedButtons[_bIndex+""] = !openedButtons[_bIndex+""]
                        me.setState({openedButtons});
                      }
                      if (_b.href) {
                        document.location.href = _b.href;
                      }
                    }}>
                      <a key={`left-menu-button-${_bIndex}`}>{_b.title}</a>
                      { _b.children && <i className={"far fa-angle-down"} style={{transform: me.state.openedButtons[_bIndex+""] ? "rotate(-180deg)" : ""}}/> }
                    </div>
                      <div className={`link-content ${me.state.openedButtons[_bIndex+""] ? "link-content-opened" : "link-content-closed"}`}>
                        {_b.children && _b.children.map((_sB, _sBIndex) => {
                          return <div className={"link"} onClick={() => {
                            if (_sB.href) {
                              document.location.href = _sB.href;
                            }
                          }}>
                            <a key={`left-menu-button-${_bIndex}-subbutton-${_sBIndex}`}>{_sB.title}</a>
                          </div>
                        })
                        }
                      </div>
                    </>
                  })}
                </div>
                <div className={"contacts"}>
                  <p style={{marginBottom: "2px"}}>Техническая поддержка:</p>
                  <p>+7 (916) 667-46-31</p>
                  <div className={"link"}>
                    <img src={"/images/icon-exit-red.png"}/>
                    <p>Выйти из аккаунта</p>
                  </div>
                  <div className={"link"}>
                    <img src={"/images/icon-exit-red.png"}/>
                    <p>Выйти из кампании</p>
                  </div>
                </div>


                {/*<div*/}
                {/*  style={{*/}
                {/*    display: "flex",*/}
                {/*    flexDirection: "column",*/}
                {/*    flexWrap: "nowrap",*/}
                {/*    alignContent: "center",*/}
                {/*    justifyContent: "space-between",*/}
                {/*    alignItems: "center",*/}
                {/*    height: "calc(100% - 160px)",*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <div>*/}
                {/*    /!*<List>*!/*/}
                {/*      /!*<ListItem key="profile">*!/*/}
                {/*      /!*  <Button*!/*/}
                {/*      /!*      variant="outlined"*!/*/}
                {/*      /!*      fullWidth*!/*/}
                {/*      /!*      className={*!/*/}
                {/*      /!*          "menu-btn " +*!/*/}
                {/*      /!*          (window.location.pathname == "/users/show"*!/*/}
                {/*      /!*              ? "active"*!/*/}
                {/*      /!*              : "")*!/*/}
                {/*      /!*      }*!/*/}
                {/*      /!*      href={"/users/show"}*!/*/}
                {/*      /!*  >*!/*/}
                {/*      /!*    Данные*!/*/}
                {/*      /!*  </Button>*!/*/}
                {/*      /!*</ListItem>*!/*/}
                {/*      /!*<ListItem key="bd">*!/*/}
                {/*      /!*  <Button*!/*/}
                {/*      /!*      variant="outlined"*!/*/}
                {/*      /!*      fullWidth*!/*/}
                {/*      /!*      className={*!/*/}
                {/*      /!*          "menu-btn " +*!/*/}
                {/*      /!*          (window.location.pathname == "/knowledge_base"*!/*/}
                {/*      /!*              ? "active"*!/*/}
                {/*      /!*              : "")*!/*/}
                {/*      /!*      }*!/*/}
                {/*      /!*      href={"/knowledge_base"}*!/*/}
                {/*      /!*  >*!/*/}
                {/*      /!*    база знаний*!/*/}
                {/*      /!*  </Button>*!/*/}
                {/*      /!*</ListItem>*!/*/}
                {/*      /!*<ListItem key="mydocs">*!/*/}
                {/*      /!*  <Button*!/*/}
                {/*      /!*      variant="outlined"*!/*/}
                {/*      /!*      fullWidth*!/*/}
                {/*      /!*      className={*!/*/}
                {/*      /!*          "menu-btn " +*!/*/}
                {/*      /!*          (window.location.pathname == "/document_templates/my"*!/*/}
                {/*      /!*              ? "active"*!/*/}
                {/*      /!*              : "")*!/*/}
                {/*      /!*      }*!/*/}
                {/*      /!*      href={"/document_templates/my"}*!/*/}
                {/*      /!*  >*!/*/}
                {/*      /!*    <span style={{ whiteSpace: "nowrap" }}>*!/*/}
                {/*      /!*      мои документы*!/*/}
                {/*      /!*    </span>*!/*/}
                {/*      /!*  </Button>*!/*/}
                {/*      /!*</ListItem>*!/*/}
                {/*      /!*<ListItem key="projects">*!/*/}
                {/*      /!*  <Button*!/*/}
                {/*      /!*    variant="outlined"*!/*/}
                {/*      /!*    fullWidth*!/*/}
                {/*      /!*    className={*!/*/}
                {/*      /!*      "menu-btn " +*!/*/}
                {/*      /!*      (window.location.pathname == "/" ? "active" : "")*!/*/}
                {/*      /!*    }*!/*/}
                {/*      /!*    href="/"*!/*/}
                {/*      /!*  >*!/*/}
                {/*      /!*    Проекты*!/*/}
                {/*      /!*  </Button>*!/*/}
                {/*      /!*</ListItem>*!/*/}
                {/*      /!*<ListItem key="staff">*!/*/}
                {/*      /!*  <Button*!/*/}
                {/*      /!*    variant="outlined"*!/*/}
                {/*      /!*    fullWidth*!/*/}
                {/*      /!*    className={"menu-btn"}*!/*/}
                {/*      /!*  >*!/*/}
                {/*      /!*    сотрудники*!/*/}
                {/*      /!*  </Button>*!/*/}
                {/*      /!*</ListItem>*!/*/}

                {/*    /!*</List>*!/*/}
                {/*  </div>*/}
                {/*  /!*<div>*!/*/}
                {/*    /!*<List>*!/*/}
                {/*    /!*  <ListItem key="settings">*!/*/}
                {/*    /!*    /!*<Button*!/*!/*/}
                {/*    /!*    /!*  variant="outlined"*!/*!/*/}
                {/*    /!*    /!*  fullWidth*!/*!/*/}
                {/*    /!*    /!*  className={"menu-btn"}*!/*!/*/}
                {/*    /!*    /!*  href={"https://www.stimul.app"}*!/*!/*/}
                {/*    /!*    /!*>*!/*!/*/}
                {/*    /!*    /!*  настройки*!/*!/*/}
                {/*    /!*    /!*</Button>*!/*!/*/}
                {/*    /!*  </ListItem>*!/*/}


                {/*    /!*  <ListItem key="signout">*!/*/}
                {/*    /!*    <div*!/*/}
                {/*    /!*      style={{*!/*/}
                {/*    /!*        display: "flex",*!/*/}
                {/*    /!*        flexDirection: "row",*!/*/}
                {/*    /!*        alignContent: "center",*!/*/}
                {/*    /!*        justifyContent: "space-between",*!/*/}
                {/*    /!*        alignItems: "center",*!/*/}
                {/*    /!*        width: "200px",*!/*/}
                {/*    /!*        height: "50px",*!/*/}
                {/*    /!*        cursor: "pointer",*!/*/}
                {/*    /!*        padding: "0px",*!/*/}
                {/*    /!*      }}*!/*/}
                {/*    /!*      onClick={() => {alert("ышел")}}*!/*/}
                {/*    /!*    >*!/*/}
                {/*    /!*      /!*<svg*!/*!/*/}
                {/*    /!*      /!*  width="30"*!/*!/*/}
                {/*    /!*      /!*  height="30"*!/*!/*/}
                {/*    /!*      /!*  viewBox="0 0 30 30"*!/*!/*/}
                {/*    /!*      /!*  fill="none"*!/*!/*/}
                {/*    /!*      /!*  xmlns="http://www.w3.org/2000/svg"*!/*!/*/}
                {/*    /!*      /!*>*!/*!/*/}
                {/*    /!*      /!*  <path*!/*!/*/}
                {/*    /!*      /!*    fillRule="evenodd"*!/*!/*/}
                {/*    /!*      /!*    clipRule="evenodd"*!/*!/*/}
                {/*    /!*      /!*    d="M7.75 10C7.75 8.75736 8.75736 7.75 10 7.75H15C15.4142 7.75 15.75 7.41421 15.75 7C15.75 6.58579 15.4142 6.25 15 6.25H10C7.92893 6.25 6.25 7.92893 6.25 10V20C6.25 22.0711 7.92893 23.75 10 23.75H15C15.4142 23.75 15.75 23.4142 15.75 23C15.75 22.5858 15.4142 22.25 15 22.25H10C8.75736 22.25 7.75 21.2426 7.75 20V10ZM13.624 12.416L12.4014 14.25H23C23.4142 14.25 23.75 14.5858 23.75 15C23.75 15.4142 23.4142 15.75 23 15.75H12.4014L13.624 17.584C13.8538 17.9286 13.7607 18.3943 13.416 18.624C13.0714 18.8538 12.6057 18.7607 12.376 18.416L10.3856 15.4305C10.3683 15.4058 10.3526 15.3802 10.3386 15.3539C10.3156 15.311 10.2967 15.2657 10.2823 15.2184C10.2605 15.1468 10.2499 15.0732 10.2499 15.0001C10.2498 14.9272 10.2604 14.8539 10.282 14.7826C10.2964 14.7349 10.3155 14.6892 10.3386 14.6461C10.3527 14.6197 10.3683 14.5942 10.3856 14.5695L12.376 11.584C12.6057 11.2393 13.0714 11.1462 13.416 11.376C13.7607 11.6057 13.8538 12.0714 13.624 12.416Z"*!/*!/*/}
                {/*    /!*      /!*    fill="#B1AFFF"*!/*!/*/}
                {/*    /!*      /!*  />*!/*!/*/}
                {/*    /!*      /!*</svg>*!/*!/*/}

                {/*    /!*      /!*<div>*!/*!/*/}
                {/*    /!*      /!*  <Typography*!/*!/*/}
                {/*    /!*      /!*    style={{*!/*!/*/}
                {/*    /!*      /!*      fontSize: "14px",*!/*!/*/}
                {/*    /!*      /!*      lineHeight: "20px",*!/*!/*/}
                {/*    /!*      /!*      fontWeight: "500",*!/*!/*/}
                {/*    /!*      /!*      color: "#6360FF",*!/*!/*/}
                {/*    /!*      /!*      opacity: "0.5",*!/*!/*/}
                {/*    /!*      /!*    }}*!/*!/*/}
                {/*    /!*      /!*  >*!/*!/*/}
                {/*    /!*      /!*    Выйти из системы*!/*!/*/}
                {/*    /!*      /!*  </Typography>*!/*!/*/}
                {/*    /!*      /!*</div>*!/*!/*/}
                {/*    /!*    </div>*!/*/}
                {/*    /!*  </ListItem>*!/*/}
                {/*    /!*</List>*!/*/}
                {/*  /!*</div>*!/*/}
                {/*</div>*/}
              </Drawer>
          )}
          <div>
            <AppBar position="fixed" color="primary">
              <Toolbar style={styles.customizeToolbar}>
                <Grid justifyContent={"space-between"} container>
                  <Grid xs={2} item>
                    {!!me.state.idToken && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                              edge="start"
                              color="inherit"
                              aria-label="menu"
                              onClick={me.toggleDrawer(true)}
                          >
                            <i
                                className="fal fa-bars"
                                style={{ marginRight: "9px" }}
                            ></i>
                          </IconButton>
                          <Typography
                              style={{
                                textTransform: "uppercase",
                                fontWeight: "600",
                                fontSize: "14px",
                                lineHeight: "20px",
                              }}
                          >
                            меню
                          </Typography>
                        </div>
                    )}
                  </Grid>
                  <Grid xs={4} item>
                    <Grid container justifyContent={"center"}>
                      <Typography
                          style={{
                            fontSize: "28px",
                            lineHeight: "40px",
                          }}
                      >
                        {me.currentTitle()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>

                  </Grid>
                  {/*<Logo/>*/}
                </Grid>
              </Toolbar>
            </AppBar>
          </div>

          {/* {!!me.state.idToken && (
          <a
            href={"/users/show"}
            style={{
              textDecoration: "none",
              cursor: "pointer",
              color: "#858585",
              color: "#524FDC",
              borderRadius: "8px",
              fontSize: "19px",
              background: "#fff",
              border: "none",
              boxShadow: "4px 4px 7px #ababab",
              whiteSpace: "nowrap",
              padding: "10px",
              paddingTop: "20px",
            }}
          >
            <i className="fad fa-user fa-2x" style={{ marginRight: "9px" }}></i>
            <span
              style={{
                position: "relative",
                top: "-6px",
                margin: "8px",
                marginBottom: "14px",
              }}
            >
              ЛИЧНЫЙ КАБИНЕТ
            </span>
          </a>
        )}

        {me.state.user === null && (
          <a
            style={{
              textDecoration: "none",
              cursor: "pointer",
              color: "#858585",
              color: "#524FDC",
              borderRadius: "8px",
              fontSize: "19px",
              background: "#fff",
              border: "none",
              boxShadow: "4px 4px 7px #ababab",
              whiteSpace: "nowrap",
              padding: "10px",
              paddingTop: "20px",
            }}
            onClick={() => keycloak.login()}
          >
            Войти
          </a>
        )}

        {me.state.user === undefined && <CircularProgress />} */}
        </ThemeProvider>
    );
  }
}
