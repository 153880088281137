import React from "react";
import DocumentView from "./DocumentView";
import "./document-edit.scss";
import $ from "jquery";
import "./document-edit.scss";
export default class DocGen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewUrl: null,
      documentId: -1,
      user: null,
      idToken: null,
      updateIntervalId: null,
    };

    this.setPreviewUrl = this.setPreviewUrl.bind(this);
    this.sendParsel = this.sendParsel.bind(this);
    this.startCheckInterval = this.startCheckInterval.bind(this);
    this.updateUserStatus = this.updateUserStatus.bind(this);
  }

  componentDidMount() {
    startCheckInterval();
  }

  startCheckInterval() {
    let me = this;
    let prevState = { ...this.state };
    let id = setInterval(me.updateUserStatus, 2000);
    prevState.updateIntervalId = id;
    me.setState(prevState);
  }

  updateUserStatus() {
    // alert(8);
    let me = this;
    if (document.user) {
      // alert(document.user);
      let prevState = this.state;
      prevState.user = document.user;
      me.setState(prevState);
      clearInterval(prevState.updateIntervalId);
      me.sendParsel();
    }
  }

  sendParsel() {
    let me = this;
    // alert(JSON.stringify(this.state.parsel));
    let url = `/document_templates/generate_pdf_document`;
    // alert(url);
    $.ajax({
      url: url,
      dataType: "json",
      type: "POST",
      data: {
        file_url: me.props.url,
      },
      success: function (res) {
        me.setPreviewUrl(res.preview_url, res.document_id);
      },
      error: function (res) {},
    });
  }

  setPreviewUrl(newUrl, documentId) {
    let prevState = { ...this.state };
    prevState.previewUrl = newUrl;
    prevState.documentId = documentId;
    this.setState(prevState);
  }

  componentDidMount() {
    this.startCheckInterval();
  }

  render() {
    let me = this;
    return (
      <>
        {!!me.state.user && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {/*<button type="button" onClick={() => keycloak.logout()}>Выйти</button>*/}
            <DocumentView
              documentId={me.state.documentId}
              setPreviewUrl={me.setPreviewUrl}
              key={`preview-${me.state.previewUrl}`}
              previewUrl={me.state.previewUrl}
            />
          </div>
        )}
      </>
    );
  }
}
