import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./knowledgebase.scss";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import $ from "jquery";

const defaultDescription = "Мы живем в мире, который давно испытывает информационную\n" +
    "                    перегрузку. Каждый человек ежедневно сталкивается с огромным\n" +
    "                    потоком информации. Задача кандидата на первоначальном этапе\n" +
    "                    – пробиться через этот поток к своим избирателям, закрепиться\n" +
    "                    у них в сознании, создатьпонятный и однозначный\n" +
    "                    положительный образ. Для того чтобы люди доверяли кандидату\n" +
    "                    во время избирательнойкампании, нужно завоевать их симпатию\n" +
    "                    и лояльность задолго до началавыборов. Это возможно только\n" +
    "                    при условии постоянного присутствия винформационной\n" +
    "                    повестке. Следовательно, чем плотнее информационныйсигнал\n" +
    "                    кандидата, тем больше вероятность, что он будет получен\n" +
    "                    именно егоизбирателями.Предположим, что на территории\n" +
    "                    избирательного округа кандидатавыходит одно печатное издание\n" +
    "                    (еженедельная газета), вещает местнаятелекомпания с\n" +
    "                    ежедневными вечерними выпусками новостей, есть местноерадио\n" +
    "                    с эфирами утром и вечером и интернет-портал, рассказывающий\n" +
    "                    обовсех интересных событиях и публикующий рекламные\n" +
    "                    объявления. Дляэффективной и качественной работы в этом\n" +
    "                    информационном поле внедельный медиаплан необходимо внести\n" +
    "                    минимум 2–3 обязательныхинформационных повода (события или\n" +
    "                    комментария). Одна публикация (илибольше) должна выйти в\n" +
    "                    еженедельной газете, 1–2 сюжета с участием илиупоминанием\n" +
    "                    имени кандидата (либо с комментарием к событию) наместном\n" +
    "                    ТВ, 3–4 новости в неделю на радио, и 2–3 публикации в\n" +
    "                    Интернете.";

export default class KnowledgeBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDescription: defaultDescription,
      sections: [],
      expanded: "",
      currentSection: null,
      search: "",
      searchResult: [],
      spravkas: [],
      searchTyping: false,
      searchTypingTimeout: 0,
      isLoading: false,
    };
  }

  async componentDidMount() {
    await this.loadSections();
  }

  async loadSections() {
    let me = this;
    let prevState = { ...this.state };

    return new Promise((resolve) => {
      const url = "/sections.json";
      fetch(url)
        .then((e) => {
          return e.json();
        })
        .then((e) => {
          prevState.sections = [...e];
          prevState.currentSection = [...e][0];
          prevState.currentDescription = prevState.currentSection.description;
          prevState.expanded = `panel-${prevState.currentSection.id}`;
          me.setState(prevState, () => {
            let spravkas = [];
            prevState.sections.forEach((section) => {
              spravkas.push(...section.spravkas);
            });
            me.setState({ spravkas: spravkas }, () => {
              resolve(e);
            });
          });
        });
    });
  }

  handleChange = (panel, id) => (event, isExpanded, description="") => {
    let me = this;
    let prevState = { ...me.state };
    if (isExpanded) {
      prevState.expanded = panel;
    } else {
      prevState.expanded = false;
    }
    if (id) {
      prevState.currentSection = me.state.sections.find(
        (section) => section.id === id
      );
      prevState.currentDescription = prevState.currentSection.description;
    }
    me.setState(prevState);
  };

  handleSearchChange = (search) => {
    let searchVar = search.toLowerCase();
    let me = this;
    me.setState({ isLoading: true });

    if (me.state.searchTypingTimeout) {
      clearTimeout(me.state.searchTypingTimeout);
    }

    me.setState({
      searchTyping: false,
      searchTypingTimeout: setTimeout(function () {
        me.searchSpravka(searchVar);
      }, 2000),
    });

    me.setState({ search: searchVar });
  };

  searchSpravka(keyword) {
    let me = this;

    $.ajax({
      url: "/search_spravkas",
      dataType: "json",
      type: "POST",
      data: {
        keyword: keyword,
      },
      success: function (res) {
        me.setState({ searchResult: [...res] });
        me.setState({ isLoading: false });
      },
      error: function (res) {},
    });
  }

  openDoc = (url, title) => {
    // console.log(url);
    document.location.href =
      "/document_templates/viewer?url=" + url + "&title=" + title;
  };

  render() {
    let me = this;

    return (
      <ThemeProvider
        theme={createTheme({
          palette: {
            primary: {
              main: "#6360FF",
            },
            success: {
              main: "#0DE147",
            },
          },
        })}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "flex-start",
            padding: "0px 30px",
          }}
        >
          <div>
            {me.state.sections.map((section) => (
              <Accordion
                expanded={me.state.expanded === `panel-${section.id}`}
                onChange={me.handleChange(`panel-${section.id}`, section.id, section.description)}
                className="knowledgebase-navigation"
                key={`section-${section.id}`}
              >
                <AccordionSummary>
                  <Typography
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      color:
                        me.state.expanded === `panel-${section.id}`
                          ? "#5354DA"
                          : "#6360FF",
                    }}
                  >
                    {section.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ display: "block" }}>
                    {section.spravkas.map((spravka) => (
                      <Typography
                        style={{
                          color: "#3E3E3E",
                          fontSize: "14px",
                          lineHeight: "20px",
                          marginBottom: "10px",
                        }}
                        key={`${section.id} - ${spravka.id}`}
                      >
                        {spravka.title}
                      </Typography>
                    ))}
                    <div
                      style={{
                        width: "10px",
                        background: "#6360ff",
                        height: "100%",

                        transition:
                          "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,\n    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        opacity: "1 !important",
                        position: "absolute",
                        left: "-30px",
                        top: "0px",
                        borderTop: "1px solid #F5F5FF",
                        borderBottom: "1px solid #F5F5FF",
                      }}
                    ></div>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: "85px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="knowledgebase-search-container">
              <TextField
                placeholder="Поиск"
                variant="outlined"
                color="primary"
                fullWidth
                margin="dense"
                value={me.state.search}
                onChange={(e) => {
                  me.handleSearchChange(e.target.value);
                }}
              />
            </div>

            {!me.state.search.length && (
              <div className="knowledgebase-content">
                <div>
                  <p className="knowledgebase-content-title">
                    {me.state.currentSection
                      ? me.state.currentSection.title
                      : ""}
                  </p>
                  <p className="knowledgebase-content-text">
                    {me.state.currentDescription}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "auto",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {/*{ me.state.currentDescription && <p className="knowledgebase-content-text">*/}
                  {/*  {me.state.currentDescription}*/}
                  {/*</p> }*/}
                  {me.state.currentSection &&
                      me.state.currentSection.spravkas.map((spravka) => (
                      <div
                        className="opendoc"
                        onClick={() =>
                          me.openDoc(spravka.file_url, spravka.title)
                        }
                        key={`spravkabtn-${spravka.id}`}
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M19.8023 4.2234C19.3342 3.76 18.7021 3.5 18.0434 3.5H8.5C7.1193 3.5 6 4.6193 6 6V24C6 25.3807 7.1193 26.5 8.5 26.5H22.5C23.8807 26.5 25 25.3807 25 24V10.412C25 9.7446 24.7331 9.1049 24.2589 8.6354L19.8023 4.2234ZM8.5 5H16.5355C17.0878 5 17.5355 5.4477 17.5355 6V10.0166C17.5355 10.5689 17.9833 11.0166 18.5355 11.0166H22.5C23.0523 11.0166 23.5 11.4643 23.5 12.0166V24C23.5 24.5523 23.0523 25 22.5 25H8.5C7.9477 25 7.5 24.5523 7.5 24V6C7.5 5.4477 7.9477 5 8.5 5ZM19.5 20.75C19.9142 20.75 20.25 21.0858 20.25 21.5C20.25 21.8797 19.9678 22.1935 19.6018 22.2432L19.5 22.25H11.5C11.0858 22.25 10.75 21.9142 10.75 21.5C10.75 21.1203 11.0322 20.8065 11.3982 20.7568L11.5 20.75H19.5ZM20.25 18.5C20.25 18.0858 19.9142 17.75 19.5 17.75H11.5L11.3982 17.7568C11.0322 17.8065 10.75 18.1203 10.75 18.5C10.75 18.9142 11.0858 19.25 11.5 19.25H19.5L19.6018 19.2432C19.9678 19.1935 20.25 18.8797 20.25 18.5ZM19.5 14.75C19.9142 14.75 20.25 15.0858 20.25 15.5C20.25 15.8797 19.9678 16.1935 19.6018 16.2432L19.5 16.25H11.5C11.0858 16.25 10.75 15.9142 10.75 15.5C10.75 15.1203 11.0322 14.8065 11.3982 14.7568L11.5 14.75H19.5ZM19.0355 6C19.0355 5.8399 19.0205 5.6833 18.9917 5.5315L23.0886 9.5863C22.8998 9.5407 22.7027 9.5166 22.5 9.5166L19.035 9.516L19.0355 6Z"
                            fill="#6360FF"
                          />
                        </svg>
                        <span>{spravka.title}</span>

                        {/* <span style={{ fontWeight: "600" }}>Открыть</span> */}
                      </div>
                    ))}
                </div>
              </div>
            )}

            <div className="knowledgebase-search-results">
              {me.state.search &&
                me.state.searchResult.length > 0 &&
                me.state.searchResult.map((spravka) => (
                  <div
                    className="search-result-card"
                    key={`search-result-${spravka.id}`}
                    onClick={() => me.openDoc(spravka.file_url, spravka.title)}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span>{spravka.title}</span>
                      <i className="fa-2x fal fa-angle-right"></i>
                    </div>
                    
                  </div>
                ))}

              {me.state.search &&
                !me.state.searchResult.length &&
                !me.state.isLoading && (
                  <div style={{ color: "#3E3E3E" }}>
                    К сожалению, ничего не найдено
                  </div>
                )}
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
