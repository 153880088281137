import React from "react";
import "./gant.scss";

function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}


function getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}


function strftime(format_date, format_string) {
    var yyyy = format_date.getFullYear();
    var yy = yyyy.toString().substring(2);
    var m = format_date.getMonth() + 1;
    var mm = m < 10 ? '0' + m : m;
    var d = format_date.getDate();
    var dd = d < 10 ? '0' + d : d;

    var H = format_date.getHours();
    var HH = H < 10 ? '0' + H : H;
    var M = format_date.getMinutes();
    var MM = M < 10 ? '0' + M : M;
    var S = format_date.getSeconds();
    var SS = S < 10 ? '0' + S : S;

    format_string = format_string.replace(/yyyy/i, yyyy);
    format_string = format_string.replace(/yy/i, yy);
    format_string = format_string.replace(/mm/i, mm);
    format_string = format_string.replace(/m/i, m);
    format_string = format_string.replace(/dd/i, dd);
    format_string = format_string.replace(/d/i, d);
    format_string = format_string.replace(/HH/i, HH);
    format_string = format_string.replace(/H/i, H);
    format_string = format_string.replace(/MM/i, MM);
    format_string = format_string.replace(/M/i, M);
    format_string = format_string.replace(/SS/i, SS);
    format_string = format_string.replace(/S/i, S);

    return format_string;
}

Date.prototype.strftime = function(format) {
    return strftime(this, format);
}

Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

export default class Gant extends React.Component {
  constructor(props) {
    super(props);
    let params = {};
    let search = location.search.substring(1);
    try {
      params = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) });
    } catch(e) {}
    this.state = {
      dates: [],
      sections: [],
      currentDate: new Date(),
      amountOfDates: 56,
      shift: !params["shift"] ? 0 : parseInt(params["shift"])
    };
    
    this.updateDocuments = this.updateDocuments.bind(this);
    this.changeShift = this.changeShift.bind(this);
    this.updateDates = this.updateDates.bind(this);
    this.changeTimeline = this.changeTimeline.bind(this);
  }


  changeTimeline(increaser) {
    let me = this;
    let prevState = {... this.state};
    // alert(prevState.amountOfDates);
    prevState.amountOfDates = prevState.amountOfDates *= increaser;
    if (prevState.amountOfDates < 14 ) {
      prevState.amountOfDates = 14;
    }
    me.setState(prevState, function() {
      me.updateDates();  
    });
    // alert(prevState.amountOfDates);
  }

  changeShift(delta) {
    let me = this;
    let prevState = {... this.state};
    prevState.shift += delta;
    this.setState(prevState);
    // alert(delta);
    setTimeout(function() {
      // me.updateDocuments();  
      me.updateDates();
    }, 200);
  }

  updateDates() {
    let today = new Date();
    let prevState = {... this.state};
    let currentDate = new Date();
    currentDate = currentDate.addDays(this.state.shift);
    currentDate = getMonday(currentDate);
    let dates = [];
    let delta = parseInt(prevState.amountOfDates / 7);
    for (let i = 0; i < this.state.amountOfDates; i+=1) {
      let date = currentDate;
      date = date.addDays(i - this.state.amountOfDates / 2);
      // if ((date.setHours(0,0,0,0) == today.setHours(0,0,0,0)) || i % delta == 0) {
      dates.push(date);
      // }/
      
    }

    prevState.currentDate = currentDate;
    prevState.dates = dates;
    this.setState(prevState);

    return currentDate;
  }

  updateDocuments() {
    let prevState = {... this.state};
    
    let me = this;
    let url = "/document_templates/all.json";
    fetch(url).then((e) => {return e.json()}).then(
      (e) => {
        let sections = [];
        for(let ss in e) {
          // sections.push(ss);
          let s = {... e[ss]};
          // alert(JSON.stringify(ss));
          let docs = [];
          for(let i in s.documents) {
            let template = {... s.documents[i]};
            template.startDate = Date.parse(template.date_start);
            template.endDate = Date.parse(template.date_end);
            docs.push(template);
          }
          s.documents = docs;
          sections.push(s);
        }
        prevState.sections = sections;
        this.setState(prevState, function() {
          me.updateDates();
        });
    });
  }
  
  componentDidMount() {
    this.updateDocuments();
  }
  

  render() {
    let me = this;
    let months = ["ЯНВ", "ФЕВ", "МАР", "АПР", "МАЙ", "ИЮН", "ИЮЛ", "СЕН", "ОКТ", "НОЯ", "ДЕК"];
    let today = new Date();
    let prevSectionName = "";
    let delta = parseInt(me.state.amountOfDates / 7);
    return (
      <table style={{userSelect: "none"}} cellSpacing="0" cellPadding="0" key={this.state.currentDate.strftime("m.d")}>
        <thead>
          <tr>
            <td style={{border: "none", maxWidth: "420px", width: "420px"}}>
              <div style={{minHeight: "130px",display: "flex", justifyContent: "space-around", alignContent: "center", alignItems: "center", width: "90%"}}>
                <i style={{pointer: "pointer"}} onClick={(e) => {me.changeShift(-me.state.amountOfDates/7);}} className="fad fa-arrow-square-left fa-5x"></i>
                <span style={{display: "flex", fontSize: "30px", color: "#fff"}}>{this.state.currentDate.addDays(- 8).strftime("dd.mm")}</span>
                <i style={{cursor: "pointer"}} className="fal fa-minus-circle" onClick={(r) => { me.changeTimeline(2) }}></i>
                <i style={{cursor: "pointer"}} className="fal fa-plus-circle"  onClick={(r) => { me.changeTimeline(0.5)   }}></i>
                <span style={{display: "flex", fontSize: "30px", color: "#fff"}}>{this.state.currentDate.addDays(+ 8).strftime("dd.mm")}</span>
                <i style={{pointer: "pointer"}} onClick={(e) => {me.changeShift(me.state.amountOfDates/7);}} className="fad fa-arrow-square-right fa-5x"></i>
              </div>
            </td>
            {me.state.dates.map( (e, key) => 
              <td key={`date-${key}`} style={{border: "none", position: "relative", overflow: "visible"}}>
                { (key % delta == 0) &&
                  <div className="date-title" style={{position: "absolute", top: "25%"}} >
                    <p className="date">{e.strftime("d")}</p>
                    <p className="month">{months[e.getMonth()]}</p>
                    { today.setHours(0,0,0,0) == e.setHours(0,0,0,0) &&
                      <div className="circle"></div>
                    }
                    { today.setHours(0,0,0,0) == e.setHours(0,0,0,0) &&
                      <div className="vertical-line"></div>
                    }
                  </div>
                }
              </td>
            )}
          </tr>
        </thead>
        <tbody>
          { me.state.sections.map ( (sec, secDoc) => 
            {
              let res = sec.documents.map( (doc, keyDoc) => {
                return <tr className={secDoc % 2 == 0 ? 'darken' : 'lighten'} key={`doc-${keyDoc}`}>
                  <td style={{width: "420px", maxWidth: "40px", borderTop: keyDoc == 0 ? "2px solid #ffffff" : "", borderBottom: "2px solid #ededed", borderRight: "2px solid #858585", maxWidth: "40px", padding: "7px", borderLeft: "none", background: "#ededed"}}>
                    { keyDoc == 0 &&
                      <div style={{display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center", marginBottom: "15px"}}>
                        <div dangerouslySetInnerHTML={{__html: sec.pic_plain}}></div>
                        <h3 style={{marginLeft: "10px"}}>{sec.title}</h3>
                      </div>
                    }
                    <a style={{fontSize: "16px", textDecoration: "none", color: "#404040", padding: "7px", margin: "10px"}} href={`/document_templates/${doc.id}`} className="document-title">{doc.name}</a>
                  </td>
                  {me.state.dates.map( (e, key2) => 
                    <td key={`date-2-${key2}`} style={ today.setHours(0,0,0,0) == e.setHours(0,0,0,0) ? { borderLeft: "4 px solid #404040", maxWidth: "40px"} : {borderLeft: "1px solid #fdfafd", maxWidth: "40px"}} >
                      { ( doc.startDate <= e && e <= doc.endDate ) &&
                        <span className="gant-part" style={doc.startDate < today.setHours(0,0,0,0) ? { background: "#FCC"} : {} }></span>
                      }
                    </td>
                  )}
                </tr>
              });
              return res;
            }
          )}
        </tbody>
      </table>
    );
  }
}